<template>
    <div></div>
</template>
<script>
import wxsdk from 'weixin-js-sdk'
import { getInfo } from '@/api/wx';

export default {
    name: 'wxlogin',
    data(){
        return {}
    },
    created(){
        //请求后端接口获取公众号openid等信息
        getInfo({code: this.$route.query.code}).then(response => {
            //跳转回小程序的登录页面并将公众号openid带过去
            wxsdk.miniProgram.redirectTo({url: "/pages/bind/bind_ble?openid=" + response.data.data.openid
                    + "&name=" + response.data.data.name + "&imgUrl=" + response.data.data.imgUrl})
        })
    }
}
</script>
